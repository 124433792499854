import React, { useLayoutEffect } from 'react';
import { MetaTags } from 'react-meta-tags';
import "../CSS/HomePage.css";
import NavBar from "../Component/NavBar";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from 'react-router-dom';
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules

import { Autoplay, Pagination, Navigation } from "swiper";


// About Cars Images 

import Car1 from "../Images/car Image.png";
import car2 from "../Images/car 2.png";
import car3 from "../Images/Image 001.png";


import { IoCarSportSharp } from 'react-icons/io5';
import { BsFillEmojiSmileFill, BsFillSuitHeartFill } from 'react-icons/bs';
import CountUp from 'react-countup';
import Footer from '../Component/Footer';




const HomePage = () => {

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });



  return (
    <>

      <MetaTags>
        <title>Friends Car| Rent car in Mumbai| Rent Car Company In Mumbai</title>
        <meta name="google-site-verification" content="SCmTg9Cuug6VzUWhPWi41Ak8PIf7cOBnDIrXg_UL7Kk" />
        <meta name="description" content="Get a Car for rent in Mumbai with Friends Car . We provide 24x7 reliable, secure, and hassle-free car rental services at affordable prices." />
        <meta name="keywords" content="rent car in Mumbai, rent car, rent car in mumbai, car rental companies, car hire,rent car company,Rent Car Company In Mumbai" />
        <meta property="og:title" content="Friends Car| Rent car in Mumbai| Rent Car Company In Mumbai" />
        <meta property="og:description" content="Get a Car for rent in Mumbai with Friends Car . We provide 24x7 reliable, secure, and hassle-free car rental services at affordable prices." />
        <meta property="og:image" content="https://friendscar.in/static/media/Friends%20car%20logo04.02b7752096dc610609d7.png" />
        <link rel="canonical" href="https://friendscar.in/" />
      </MetaTags>
      {/* ------------------------------- Section 1 ------------------------------ */}

      <NavBar />
      <section className='Home-Section-1'>
        <div className='Home-Banner-Con'>
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className='Home-Banner Homebanner01'>
                {/* <h1>Find Best Car & Limousine</h1> */}
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='Home-Banner Homebanner02'>
                {/* <h2>If you’re looking for a budget-friendly rental car for your road trip. Friends car is a great choice.</h2> */}
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='Home-Banner Homebanner03'>
                {/* <h2 >Whether it's your wedding day or any special occasion,Friends car can transport you in style.</h2> */}
              </div>
            </SwiperSlide>
          </Swiper>
        </div>

      </section>


      {/* ------------------------------- Section 2 ------------------------------ */}

      <section className='Home-Section-2'>
        <div className='container'>
          <div className='row'>
            <div class="col-lg-6">
              <div class="about-left">
                <h4>about us</h4>
                <h2>Welcome to Friends Car</h2>
                <p>Friends Car is regarded as the #1 Car Rental in Mumbai with the most beautiful facilities and an amazing selection of vehicle models to suit your every need and desire. We are committed to providing great service, and secure and reliable rentals at value rates.</p>
                <div class="about-list">
                  <ul>
                    <li><i class="fa fa-check"></i>We are a trusted name</li>
                    <li><i class="fa fa-check"></i>we deal in have all brands</li>
                    <li><i class="fa fa-check"></i>have a larger stock of vehicles</li>
                    <li><i class="fa fa-check"></i>we are at worldwide locations</li>
                  </ul>
                </div>
                <div class="about-signature">
                  {/* <div class="signature-left">
                    <img src="assets/img/signature.png" alt="signature" />
                  </div> */}
                  <div class="signature-right">
                    <h3>Robertho Garcia</h3>
                    {/* <p>President</p> */}
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='AboutUS-Cars-Section'>
                <Swiper
                  spaceBetween={30}
                  centeredSlides={true}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  navigation={true}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="mySwiper"
                >
                  <SwiperSlide>
                    <div className='AboutUS-Cars-Image-con'>
                      <img src={Car1} alt="audi" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className='AboutUS-Cars-Image-con'>
                      <img src={car2} alt="mardiz benz" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className='AboutUS-Cars-Image-con'>
                      <img src={car3} alt="" />
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ------------------------------- Section 3 ------------------------------ */}

      <section className='Home-Section-3'>
        <div className='container'>
          <div className='Home-Section-OurFleet-Maincon'>
            <div className='Home-Section-OurFleet-con'>
              <div className='Home-Section-OurFleet-Heading'>
                <h5>Our Fleet, Your Fleet</h5>
              </div>
              <div className='Home-Section-OurFleet-Para'>
                <p>
                  Friends Car Rental features a new fleet of cars, and consists of Quality Vehicle that are kept in top condition in order to ensure the comfort and safety of all potential customers.
                </p>
              </div>
              <div className='Home-Section-OurFleet-contact'>
                <a>
                  Call Now (+91) 810-829-0101
                </a>
              </div>
              <div className='Home-Section-OurFleet-btn'>
                <Link to="/rentalcars">
                  <button>
                    Request a Quote
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ------------------------------- Section 4 ------------------------------ */}

      <section className='Home-Section-4'>
        <div className='container'>
          <div className='Home-Section4-con'>
            <div className='Home_section4-WhyUs-heading-con'>
              <div className='Home_section4-WhyUs-heading'>
                <h4>Why Choose Us</h4>
                <p>Friends Car Rental has the most state of the art and modern car rental facilities in Mumbai.</p>
              </div>
            </div>
            <div className='Home_section4-WhyUs-card-con'>
              <div className='row'>

                <div className='col-md-4'>
                  <div className='Why-us-card-body'>
                    <div className='Why-us-card-Image-con'>
                      <div className='Why-us-card-Image'>
                        <IoCarSportSharp />
                      </div>
                    </div>
                    <div className='Why-us-card-details-con'>
                      <h4>
                        Variety of Car Brands
                      </h4>
                      <p>
                        In an effort to help you enjoy your vacation to the fullest, we have compiled a large selection of cars for you to choose from.
                      </p>
                    </div>
                  </div>
                </div>

                <div className='col-md-4'>
                  <div className='Why-us-card-body'>
                    <div className='Why-us-card-Image-con'>
                      <div className='Why-us-card-Image'>
                        <BsFillEmojiSmileFill />
                      </div>
                    </div>
                    <div className='Why-us-card-details-con'>
                      <h4>
                        Best Rate Guarantee
                      </h4>
                      <p>
                        You will find a variety of cars, vans, jeeps etc. at the best prices, with reliable service, and added convenience.
                      </p>
                    </div>
                  </div>
                </div>

                <div className='col-md-4'>
                  <div className='Why-us-card-body'>
                    <div className='Why-us-card-Image-con'>
                      <div className='Why-us-card-Image'>
                        <BsFillSuitHeartFill />
                      </div>
                    </div>
                    <div className='Why-us-card-details-con'>
                      <h4>
                        Awesome Customer Support
                      </h4>
                      <p>
                        We have been known for  best rental car service with the entire premises designed for super-efficient and fast, personalized service.
                      </p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>


      {/* ///////////////////////// Section 4 ///////////////////// */}
      <section className='AboutUs-Section-4'>
        <div className='container'>
          <div className='AboutUs-Section-4-Heading'>
            <h2>We are happy for our achievement</h2>
          </div>
          <div className='row'>
            <div className='col-md-4 col-sm-4'>
              <div className='Counter-Container'>
                <h2>
                  <CountUp delay={2} end={100} >

                  </CountUp>
                </h2>
                <p>car rent</p>
              </div>
            </div>
            <div className='col-md-4 col-sm-4'>
              <div className='Counter-Container'>
                <h2>
                  <CountUp delay={2} end={500} >

                  </CountUp>
                </h2>
                <p>cars</p>
              </div>
            </div>
            <div className='col-md-4 col-sm-4'>
              <div className='Counter-Container'>
                <h2>
                  <CountUp delay={2} end={500} >

                  </CountUp>
                </h2>
                <p>happy customer</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />

    </>
  )
}

export default HomePage