import React, { useEffect } from 'react';
import { HiStar, HiOutlineStar, HiOutlineUser } from 'react-icons/hi';
import { BsHandbag } from 'react-icons/bs';
import { AiOutlineControl } from 'react-icons/ai';
import DemoImage from "../Images/Demo Service.jpg";
import Enquiry from '../Page/Enquiry';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

const Carscard = ({ CarsName, FillStar1, FillStar2, FillStar3, FillStar4, FillStar5, Reviews, People, Bags, GType,CarsImage01,CarsImage02,CarsImage03,CarsImage04,CarsImage05,CarsImage06,CarsImage07,CarsImage08,CarsImage09 }) => {


    const [modalShow, setModalShow] = React.useState(false)

    // useEffect(() => {
    //     setSportList(defaultSports);
    // }, []);


    return (
        <>
            <div className='col-md-6 col-sm-6'>
                <div className='Cars-Card-Main-con'>
                    <div className='Cars-Card-con'>
                        <Swiper
                            spaceBetween={30}
                            centeredSlides={true}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={true}
                            modules={[Autoplay, Pagination, Navigation]}
                            className="mySwiper"
                        >
                            <SwiperSlide>
                                <div className='Cars-Card-Image-con'>
                                    <img src={CarsImage01} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Cars-Card-Image-con'>
                                    <img src={CarsImage02} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Cars-Card-Image-con'>
                                    <img src={CarsImage03} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Cars-Card-Image-con'>
                                    <img src={CarsImage04} alt="" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='Cars-Card-Image-con'>
                                    <img src={CarsImage05} alt="" />
                                </div>
                            </SwiperSlide>
                        </Swiper>
                        <div className='Cars-Card-Details'>
                            <h2>{CarsName}</h2>
                            <div className='Cars-Card-Star-con'>
                                <div className='First-Star'>
                                    {FillStar1}
                                </div>
                                <div className='First-Star'>
                                    {FillStar2}
                                </div>
                                <div className='First-Star'>
                                    {FillStar3}
                                </div>
                                <div className='First-Star'>
                                    {FillStar4}
                                </div>
                                <div className='First-Star'>
                                    {FillStar5}
                                </div>
                                <div className='Cars-Card-reviews-text'>
                                    <h3>{Reviews}</h3>
                                </div>
                            </div>
                            <div className='Last-car-Info-con'>
                                <div className='Last-Car-Info'>
                                    <div className='Last-Car-Info-person'>
                                        <div className='Last-Car-Info-icons'>
                                            <HiOutlineUser />
                                        </div>
                                        <div className='Last-Car-Info-icons-details'>
                                            <h4>{People}</h4>
                                        </div>
                                    </div>
                                    <div className='Last-Car-Info-person'>
                                        <div className='Last-Car-Info-icons'>
                                            <BsHandbag />
                                        </div>
                                        <div className='Last-Car-Info-icons-details'>
                                            <h4>{Bags}</h4>
                                        </div>
                                    </div>
                                    <div className='Last-Car-Info-person'>
                                        <div className='Last-Car-Info-icons'>
                                            <AiOutlineControl />
                                        </div>
                                        <div className='Last-Car-Info-icons-details'>
                                            <h4>{GType}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className='Cars-card-Btn'>
                                    <button onClick={() => setModalShow(true)}>
                                        Book Now
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Enquiry
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    )
}

export default Carscard