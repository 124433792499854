import React, { useLayoutEffect } from 'react';
import { MetaTags } from 'react-meta-tags';
import "../CSS/Service.css";
import NavBar from '../Component/NavBar';
import { IoCarSportSharp } from 'react-icons/io5';
import { BsFillEmojiSmileFill, BsFillSuitHeartFill } from 'react-icons/bs';
import Footer from "../Component/Footer";
import CarRental from "../Images/Service/Car Rental Service.jpg";
import CarForTrips from '../Images/Service/Car For Trip.jpg';
import CarForPartyAndWeddings from "../Images/Service/Car For Party And Wedding01.jpg";
import luxuryCars from "../Images/Service/Luxury Cars.webp";
import { Link } from 'react-router-dom';

const Sevices = () => {

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  return (
    <>

      <MetaTags>
        <title>Rent Car in India for Unforgettable Adventures| Friends Car</title>
        <meta name="google-site-verification" content="SCmTg9Cuug6VzUWhPWi41Ak8PIf7cOBnDIrXg_UL7Kk" />
        <meta name="description" content="Experience the Freedom and Flexibility of  Rent Car in India. Unlock Extraordinary Journeys and Embrace the Beauty of this Vibrant Nation. Book Now!" />
        <meta name="keywords" content="rent car in Mumbai, rent car, rent car in mumbai, car rental companies, car hire,rent car company,Rent Car Company In Mumbai" />
        <meta property="og:title" content="Rent Car in India for Unforgettable Adventures| Friends Car" />
        <meta property="og:description" content="Get a Car for rent in Mumbai with Friends Car . We provide 24x7 reliable, secure, and hassle-free car rental services at affordable prices." />
        <link rel="canonical" href="https://friendscar.in/rent-car-in-india" />
      </MetaTags>

      {/* -------------------------- Service Section 1 -------------------------------- */}

      <NavBar />
      <section className='Service-Section-1'>
        <div className='Service-banner-Text-1'>
          <div className='Service-banner-1-con'>
            <h1>
              Service
            </h1>
          </div>
        </div>
      </section>

      {/* ------------------------------- Section 4 ------------------------------ */}

      <section className='Home-Section-4'>
        <div className='container'>
          <div className='Home-Section4-con'>
            <div className='Home_section4-WhyUs-heading-con'>
              <div className='Home_section4-WhyUs-heading'>
                <h2>Premium Car Rental & Limousines Services</h2>
              </div>
            </div>
            <div className='Home_section4-WhyUs-card-con'>
              <div className='row'>

                <div className='col-md-4'>
                  <div className='Why-us-card-body'>
                    <div className='Why-us-card-Image-con'>
                      <div className='Why-us-card-Image'>
                        <IoCarSportSharp />
                      </div>
                    </div>
                    <div className='Why-us-card-details-con'>
                      <h4>
                        Variety of Car Brands
                      </h4>
                      <p>
                        In an effort to help you enjoy your vacation to the fullest, we have compiled a large selection of cars for you to choose from.
                      </p>
                    </div>
                  </div>
                </div>

                <div className='col-md-4'>
                  <div className='Why-us-card-body'>
                    <div className='Why-us-card-Image-con'>
                      <div className='Why-us-card-Image'>
                        <BsFillEmojiSmileFill />
                      </div>
                    </div>
                    <div className='Why-us-card-details-con'>
                      <h4>
                        Best Rate Guarantee
                      </h4>
                      <p>
                        You will find a variety of cars, vans, jeeps etc. at the best prices, with reliable service, and added convenience.
                      </p>
                    </div>
                  </div>
                </div>

                <div className='col-md-4'>
                  <div className='Why-us-card-body'>
                    <div className='Why-us-card-Image-con'>
                      <div className='Why-us-card-Image'>
                        <BsFillSuitHeartFill />
                      </div>
                    </div>
                    <div className='Why-us-card-details-con'>
                      <h4>
                        Awesome Customer Support
                      </h4>
                      <p>
                        We have been known for  best rental car service with the entire premises designed for super-efficient and fast, personalized service.
                      </p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>

      {/* -------------------------- Service Section 2 -------------------------------- */}

      <section className='Service-Section-2'>


        <div className='Service-Section-2-con'>
          <div className='row'>
            <div className='col-md-6 serviceCardpad'>
              <div className="Section2-ServiceImages-con">
                <div className='Section2-ServiceImages'>
                  <img src={CarRental} alt="Car Rental Company" />
                </div>
              </div>
            </div>
            <div className='col-md-6 serviceCardpad'>
              <div className='Service-Section2-details-con'>
                <div className='Service-Section2-details'>
                  <h3>Car Rental</h3>
                  <p>
                    Take advantage of our incredible cars, which we offer for competitively low prices. Book your car rental from Friends car and see for yourself that we value our clients.
                  </p>
                  <div className='Service-Deyails-Btn'>
                    <Link to="/rent-car">
                      <button>
                        View Cars
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className='Service-Section-2-con'>
          <div className='row'>
            <div className='col-md-6 order-md-2 order-1 serviceCardpad'>
              <div className="Section2-ServiceImages-con">
                <div className='Section2-ServiceImages'>
                  <img src={CarForTrips} alt="Car Rent For Trips" />
                </div>
              </div>
            </div>
            <div className='col-md-6 order-md-1 order-2 serviceCardpad'>
              <div className='Service-Section2-details-con'>
                <div className='Service-Section2-details'>
                  <h3>car for trips</h3>
                  <p>
                    Road trips have become increasingly popular since the pandemic. Rental cars are a great choice for travelers who want to explore new cities at their leisure. Here we are provide you the best service for your car trip.
                  </p>
                  <div className='Service-Deyails-Btn'>
                    <Link to="/rent-car">
                      <button>
                        View Cars
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className='Service-Section-2-con'>
          <div className='row'>
            <div className='col-md-6 serviceCardpad'>
              <div className="Section2-ServiceImages-con">
                <div className='Section2-ServiceImages'>
                  <img src={CarForPartyAndWeddings} alt="Car Rent For Party And Weddings" />
                </div>
              </div>
            </div>
            <div className='col-md-6 serviceCardpad'>
              <div className='Service-Section2-details-con'>
                <div className='Service-Section2-details'>
                  <h3>car for party and weddings</h3>
                  <p>
                    Wedding or party car rental! Renting a classic, romantic car for your wedding or party will make your big day extra special. It’s the perfect way to celebrate one of life’s great moments and give you everlasting memories full of pride and joy.
                  </p>
                  <div className='Service-Deyails-Btn'>
                    <Link to="/rent-car">
                      <button>
                        View Cars
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className='Service-Section-2-con'>
          <div className='row'>
            <div className='col-md-6 order-md-2 order-1 serviceCardpad'>
              <div className="Section2-ServiceImages-con">
                <div className='Section2-ServiceImages'>
                  <img src={luxuryCars} alt="luxury Cars Reantal" />
                </div>
              </div>
            </div>
            <div className='col-md-6 order-md-1 order-2 serviceCardpad'>
              <div className='Service-Section2-details-con'>
                <div className='Service-Section2-details'>
                  <h3>luxury cars</h3>
                  <p>
                    Drive your dream car with a luxury car rental from friends car, whether it’s a convertible or sports car. Enjoy an exotic car rental.
                  </p>
                  <div className='Service-Deyails-Btn'>
                    <Link to="/rent-car">
                      <button>
                        View Cars
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />

    </>
  )
}

export default Sevices