import React from 'react';
import "../CSS/Condetails.css";
import { IoLogoWhatsapp } from 'react-icons/io5';

const Condetails = () => {
    return (
        <>
            <div className='ContBtn-con'>
                <div className='ContBtn'>
                    <a href="https://wa.me/+91 8108290101?text=" target='blank'>
                        <IoLogoWhatsapp/>
                    </a>

                </div>
            </div>
        </>
    )
}

export default Condetails