import './App.css';
import HomePage from "./Page/HomePage";
import Service from "./Page/Sevices";
import Cars from "./Page/Cars";
import AboutUs from "./Page/AboutUs";
import ContactUs from "./Page/ContactUs";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NavBar from './Component/NavBar.jsx';
import CallBtn from './Component/CallBtn';
import Condetails from './Component/Condetails';

function App() {
  return (
    <div className="App">
      <BrowserRouter >
        {/* <NavBar /> */}
        <CallBtn />
        <Condetails/>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/rent-car-in-india" element={<Service />} />
          <Route path="/rent-car" element={<Cars />} />
          <Route path="/rent-car-nearby" element={<AboutUs />} />
          <Route path='/rental-car' element={<ContactUs />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
