import React from 'react';
import "../CSS/Footer.css";
import { GiRotaryPhone } from 'react-icons/gi';
import { SiGooglemaps } from 'react-icons/si';
import { AiFillClockCircle, AiOutlineTwitter, AiFillYoutube, AiFillInstagram } from 'react-icons/ai';
import { GrFacebookOption } from 'react-icons/gr';
import { IoLogoWhatsapp } from 'react-icons/io5';
import { Link } from "react-router-dom";
import Logo from "../Images/Friends car logo04.png";


const Footer = () => {

    return (
        <>
            <section className='Footer-Section'>
                <div className='Footer-Section-Main'>

                    <div className='Footer-MainSection'>
                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='Footer-MainSection-col-1'>
                                    <h4>About Us</h4>
                                    <p>
                                        Getting dressed up and traveling with good friends makes for a shared, unforgettable experience.
                                    </p>
                                    <div className='Footer-Logo-con'>
                                        <div className='Footer-Logo'>
                                            <img src={Logo} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-4'>
                                <div className='Footer-MainSection-col-2'>
                                    <h4>Contact Info</h4>
                                    <div className='Footer-iconsDetails-con'>
                                        <div className='Footer-iconsDetails'>
                                            <div className='Footer-icons'>
                                                <GiRotaryPhone />
                                            </div>
                                            <div className='Footer-Details'>
                                                (+91) 810-829-0101
                                            </div>
                                        </div>
                                        <div className='Footer-iconsDetails'>
                                            <div className='Footer-icons'>
                                                <SiGooglemaps />
                                            </div>
                                            <div className='Footer-Details'>
                                                169,1st Floor,City Mall,Oshiwara Link Rd, Phase D, Shastri Nagar, Andheri West, Mumbai - 400102.
                                            </div>
                                        </div>
                                        <div className='Footer-iconsDetails'>
                                            <div className='Footer-icons'>
                                                <AiFillClockCircle />
                                            </div>
                                            <div className='Footer-Details'>
                                                Mon - Sat 8.00 - 18.00 Sunday CLOSED
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='Footer-Social-Media'>
                                    <div className='Footer-Social-ICons-con'>
                                        <div className='Footer-Social-ICons facebook'>
                                            <a href="https://www.facebook.com/Friendscar.in" target="blank">
                                                <GrFacebookOption />
                                            </a>
                                        </div>
                                    </div>
                                    <div className='Footer-Social-ICons-con'>
                                        <div className='Footer-Social-ICons Insta'>
                                            <a href="https://www.instagram.com/Friendscar.in/" target="blank">
                                                <AiFillInstagram />
                                            </a>
                                        </div>
                                    </div>
                                    <div className='Footer-Social-ICons-con'>
                                        <div className='Footer-Social-ICons whtasapp'>
                                            <a href="https://wa.me/+91 8108290101?text=">
                                                <IoLogoWhatsapp />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-4'>
                                <div className='Footer-MainSection-col-3'>
                                    <h4>Newsletter</h4>
                                    <p>Don't miss a thing! Sign up to receive daily deals</p>
                                    <div className='Footer-mail'>
                                        <input placeholder='Your Email Address' />
                                    </div>
                                    <div className='Footer-Mail-btn'>
                                        <button>Subscribe</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr className='footer-Divide'>
                    </hr>

                    <div className='Footer-Copyright-sec'>
                        <div className='row'>
                            <div className='col-md-6 order-md-1 order-2'>
                                <div className='Footer-Copyright-con'>
                                    <p>
                                        Copyright © Friends Car All rights reserved.
                                    </p>

                                    <h4>
                                        Created by : <a href="https://skdm.in/" target='blank'> Shree Krishna Digital Marketing</a>
                                    </h4>
                                </div>
                            </div>
                            <div className='col-md-6 order-md-2 order-1'>
                                <div className='Footer-Pages'>
                                    <h6>
                                        <Link to="/">Home</Link>
                                    </h6>
                                    <h6>
                                        <Link to="/rent-car-in-india">Service</Link>
                                    </h6>
                                    <h6>
                                        <Link to="/rent-car">cars</Link>
                                    </h6>
                                    <h6>
                                        <Link to="/rent-car-nearby">About Us</Link>
                                    </h6>
                                    <h6>
                                        <Link to="/rental-car">Contact Us</Link>
                                    </h6>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>

            </section>
        </>
    )
}

export default Footer