import React, { useState, useEffect, useMemo, useLayoutEffect } from 'react';
import { MetaTags } from 'react-meta-tags';
import "../CSS/Cars.css";
import NavBar from '../Component/NavBar';
import { AiOutlineControl } from 'react-icons/ai';
import { BsHandbag } from 'react-icons/bs';
import { GiRotaryPhone, GiAlarmClock } from 'react-icons/gi';
import { HiStar, HiOutlineStar, HiOutlineUser } from 'react-icons/hi';
import { AiFillClockCircle, AiOutlineTwitter, AiFillYoutube, AiFillInstagram } from 'react-icons/ai';
import { GrFacebookOption } from 'react-icons/gr';
import { IoLogoWhatsapp } from 'react-icons/io5';

import Footer from "../Component/Footer";
import DemoImage from "../Images/Demo Service.jpg";
import CarsData from '../Component/CarsData';
import Carscard from '../Component/Carscard';

const Cars = () => {


  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  const [sportList, setSportList] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState();

  // Add default value on page load
  useEffect(() => {
    setSportList(CarsData);
  }, []);

  // Function to get filtered list
  function getFilteredList() {
    // Avoid filter when selectedCategory is null
    if (!selectedCategory) {
      return sportList;
    }
    return sportList.filter((item) => item.category === selectedCategory);
  }

  var filteredList = useMemo(getFilteredList, [selectedCategory, sportList]);

  function handleCategoryChange(event) {
    setSelectedCategory(event.target.value);
  }

  return (
    <>

      <MetaTags>
        <title>" Top-notch Rent Car at Budget-friendly Prices!"  |  Friends Car    </title>
        <meta name="description" content="Your page description" />
        <meta name="google-site-verification" content="SCmTg9Cuug6VzUWhPWi41Ak8PIf7cOBnDIrXg_UL7Kk" />
        <meta name="keywords" content="Need a  Rent Car for a day, a week, or longer? Friends Car services ensure you never compromise on quality or affordability." />
        <meta property="og:title" content="Top-notch Rent Car at Budget-friendly Prices!  |  Friends Car" />
        <meta property="og:description" content="Your Open Graph description" />
        <meta property="og:image" content="URL to your Open Graph image" />
        <link rel="canonical" href="https://friendscar.in/rent-car" />
      </MetaTags>


      {/* -------------------------- Cars Section 1 -------------------------------- */}

      <NavBar />
      <section className='Cars-Section-1'>
        <div className='Cars-banner-Text-1'>
          <div className='Cars-banner-1-con'>
            <h1>
              Cars
            </h1>
          </div>
        </div>
      </section>

      {/* -------------------------- Cars Section 2 -------------------------------- */}

      <section className='Cars-Section-2'>

        <div className='container'>
          <div className='row'>
            <div className='col-md-8'>
              <div className='Cars-Section-Show'>
                <div className='row'>
                  {filteredList.map((element, index) => (
                    <Carscard {...element} key={index} />
                  ))}
                </div>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='Cars-Section-Details'>

                <div className='Filter-Cars-Main-Con'>
                  <div className='Filer-Cras-Con'>
                    <select
                      name="category-list"
                      id="category-list"
                      onChange={handleCategoryChange}
                    >
                      <option value="">All</option>
                      <option value="Hatchback">Hatchback</option>
                      <option value="Sedan">Sedan</option>
                      <option value="Muv Cars">Muv Cars</option>
                      <option value="Suv Cars">Suv Cars</option>
                      <option value="Luxry Car">Luxry Car</option>
                    </select>
                  </div>
                </div>


                <div className='Cars-Section-contact-Details'>
                  <h4>For More Informations</h4>
                  <div className='Cars-Section-contact-Detailsandicons'>
                    <div className='cars-section-detailsicon'>
                      <GiRotaryPhone />
                    </div>
                    <div className='cars-section-detailstext'>
                      <p>(+91) 810-829-0101</p>
                    </div>
                  </div>
                  <div className='Cars-Section-contact-Detailsandicons'>
                    <div className='cars-section-detailsicon'>
                      <GiAlarmClock />
                    </div>
                    <div className='cars-section-detailstext'>
                      <p>Mon - Sat 8.00 - 18.00</p>
                    </div>
                  </div>
                </div>

                <div className='Cars-map-con'>
                  <div className='Cars-map'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.293551304953!2d72.82925001423146!3d19.13862348705198!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b63cd0ce884f%3A0x5a3f9df6a2063fc5!2sCiti%20Mall!5e0!3m2!1sen!2sin!4v1681118932590!5m2!1sen!2sin" width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>                  </div>
                </div>

                <div className='Cars-Social-Media-con'>
                  <h4>For More Informations</h4>

                  <div className='Cars-Social-Media-icons-con'>
                    <div className='Cars-Social-Media-icons facebook'>
                      <a href="https://www.facebook.com/friendscar786/" target="blank">
                        <GrFacebookOption />
                      </a>
                    </div>
                    {/* <div className='Cars-Social-Media-icons twitter'>
                      <AiOutlineTwitter />
                    </div> */}
                    {/* <div className='Cars-Social-Media-icons youtube'>
                      <AiFillYoutube />
                    </div> */}
                    <div className='Cars-Social-Media-icons Insta'>
                      <a href="https://www.instagram.com/friendscar786/" target="blank">
                        <AiFillInstagram />
                      </a>
                    </div>
                    <div className='Cars-Social-Media-icons whatsapp'>
                      <a href="https://wa.me/9320428428?text=" target="blank">
                        <IoLogoWhatsapp />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  )
}

export default Cars