import React from 'react';
import "../CSS/CallBtn.css";
import { IoCall } from 'react-icons/io5';

const CallBtn = () => {
    return (
        <>
            <div className='CallBtn-con'>
                <div className='CallBtn'>
                    <a href="tel:810-829-0101" target='blank'>
                        <IoCall />
                    </a>
                </div>
            </div>
        </>
    )
}

export default CallBtn