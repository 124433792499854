import React, { useState, useLayoutEffect } from 'react';
import { MetaTags } from 'react-meta-tags';
import "../CSS/AboutUs.css";
import NavBar from '../Component/NavBar';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules

import { Autoplay, Pagination, Navigation } from "swiper";


// About Cars Images 

import Car1 from "../Images/car Image.png";
import car2 from "../Images/car 2.png";
import car3 from "../Images/Image 001.png";


import { IoCarSportSharp } from 'react-icons/io5';
import { BsFillEmojiSmileFill, BsFillSuitHeartFill } from 'react-icons/bs';
import CountUp from 'react-countup';
import Footer from '../Component/Footer';

const AboutUs = () => {


  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });


  return (
    <>

      <MetaTags>
        <title>Get Behind the Wheel: Affordable  Rent Car Nearby | Friends Car</title>
        <meta name="google-site-verification" content="SCmTg9Cuug6VzUWhPWi41Ak8PIf7cOBnDIrXg_UL7Kk" />
        <meta name="description" content="Your page description" />
        <meta name="keywords" content="Discover hassle-free  Rent Car Nearby and embark on your next adventure. Whether it's a weekend getaway or a cross-country road trip, find the perfect wheels" />
        <meta property="og:title" content="Get Behind the Wheel: Affordable  Rent Car Nearby | Friends Car" />
        <meta property="og:description" content="Your Open Graph description" />
        <meta property="og:image" content="URL to your Open Graph image" />
        <link rel="canonical" href="https://friendscar.in/rent-car-nearby" />

      </MetaTags>


      {/* -------------------------- AboutUs Section 1 -------------------------------- */}

      <NavBar />
      <section className='AboutUs-Section-1'>
        <div className='AboutUs-banner-Text-1'>
          <div className='AboutUs-banner-1-con'>
            <h1>
              AboutUs
            </h1>
          </div>
        </div>
      </section>

      {/* -------------------------- AboutUs Section 2 -------------------------------- */}

      {/* ------------------------------- Section 2 ------------------------------ */}

      <section className='Home-Section-2'>
        <div className='container'>
          <div className='row'>
            <div class="col-lg-6">
              <div class="about-left">
                <h4>about us</h4>
                <h2>Welcome to Friends Car</h2>
                <p>Friends Car is regarded as the #1 Car Rental in Mumbai with the most beautiful facilities and an amazing selection of vehicle models to suit your every need and desire. We are committed to providing great service, and secure and reliable rentals at value rates.</p>
                <div class="about-list">
                  <ul>
                    <li><i class="fa fa-check"></i>We are a trusted name</li>
                    <li><i class="fa fa-check"></i>we deal in have all brands</li>
                    <li><i class="fa fa-check"></i>have a larger stock of vehicles</li>
                    <li><i class="fa fa-check"></i>we are at worldwide locations</li>
                  </ul>
                </div>
                <div class="about-signature">
                  {/* <div class="signature-left">
                    <img src="assets/img/signature.png" alt="signature" />
                  </div> */}
                  <div class="signature-right">
                    <h3>Robertho Garcia</h3>
                    <p>President</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='AboutUS-Cars-Section'>
                <Swiper
                  spaceBetween={30}
                  centeredSlides={true}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  navigation={true}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="mySwiper"
                >
                  <SwiperSlide>
                    <div className='AboutUS-Cars-Image-con'>
                      <img src={Car1} alt="Audi" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className='AboutUS-Cars-Image-con'>
                      <img src={car2} alt="Mardiz Benz" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className='AboutUS-Cars-Image-con'>
                      <img src={car3} alt="" />
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </section>


      {/* ///////////////////////// Section 4 ///////////////////// */}
      <section className='AboutUs-Section-4'>
        <div className='container'>
          <div className='AboutUs-Section-4-Heading'>
            <h2>We are happy for our achievement</h2>
          </div>
          <div className='row'>
            <div className='col-md-4 col-md-4'>
              <div className='Counter-Container'>
                <h2>
                  <CountUp delay={2} end={100} >

                  </CountUp>
                </h2>
                <p>car rent</p>
              </div>
            </div>
            <div className='col-md-4 col-md-4'>
              <div className='Counter-Container'>
                <h2>
                  <CountUp delay={2} end={500} >

                  </CountUp>
                </h2>
                <p>cars</p>
              </div>
            </div>
            <div className='col-md-4 col-md-4'>
              <div className='Counter-Container'>
                <h2>
                  <CountUp delay={2} end={500} >

                  </CountUp>
                </h2>
                <p>happy customer</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />


    </>
  )
}

export default AboutUs