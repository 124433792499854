import "../CSS/NavBar.css"

import { useState } from "react";

import { Nav, Navbar, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { GrFacebookOption, GrInstagram, GrLinkedinOption, GrTwitter } from 'react-icons/gr';
import { RiWhatsappFill } from 'react-icons/ri';
import Logo from "../Images/Friends car logo04.png";





const NavBar = () => {

    // Change Nav color  when scrolling

    const [color, setColor] = useState(false)

    const changeColor = () => {
        if (window.scrollY >= 78) {
            setColor(true)
        } else {
            setColor(false)
        }
    }

    window.addEventListener('scroll', changeColor)

    return (
        <>
            <div className="nav-bar-component">
                <section className="NavSection">
                    <Navbar collapseOnSelect expand="lg" variant="dark" className={color ? "NavBar NavBar-bg" : "NavBar"}>
                        <Container>
                            <Navbar.Brand href="#home">
                                <Link to="/">
                                    <div className="Logo-Container">
                                        <img src={Logo} alt="" />
                                    </div>
                                </Link>
                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                            <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-center">
                                <Nav>
                                    <Link to="/">Home</Link>
                                    <Link to="/rent-car-in-india">Services</Link>
                                    <Link to="/rent-car">Cars</Link>
                                    <Link to="/rent-car-nearby">About Us</Link>
                                    <Link to="/rental-car">Contact Us</Link>
                                </Nav>
                            </Navbar.Collapse>
                            <Nav className="Social-media-icons">
                                <Nav.Link href="https://www.facebook.com/Friendscar.in" target="blank"><GrFacebookOption /></Nav.Link>
                                <Nav.Link href="https://wa.me/+91 8108290101?text="><RiWhatsappFill /></Nav.Link>
                                <Nav.Link href="https://www.instagram.com/Friendscar.in/" target="blank"><GrInstagram /></Nav.Link>
                            </Nav>
                        </Container>
                    </Navbar>
                </section>

            </div>
        </>
    )

}

export default NavBar;