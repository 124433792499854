import DemoImage from "../Images/Demo Service.jpg";
import { HiStar, HiOutlineStar, HiOutlineUser } from 'react-icons/hi';

// Baleno Hatchback 201

import BalenoHatchback201 from "../Images/Cateagory/HatchBack/Baleno Hatchback 201.png";
import BalenoHatchback202 from "../Images/Cateagory/HatchBack/Baleno Hatchback 202.jpeg";
import BalenoHatchback203 from "../Images/Cateagory/HatchBack/Baleno Hatchback 203.jpeg";
import BalenoHatchback204 from "../Images/Cateagory/HatchBack/Baleno Hatchback 204.jpeg";
import BalenoHatchback205 from "../Images/Cateagory/HatchBack/Baleno Hatchback 205.jpeg";

// Swift Hatchback101

import SwiftHatchback101 from "../Images/Cateagory/HatchBack/Swift Hatchback101.png";
import SwiftHatchback102 from "../Images/Cateagory/HatchBack/Swift Hatchback102.jpeg";
import SwiftHatchback103 from "../Images/Cateagory/HatchBack/Swift Hatchback104.jpeg";
import SwiftHatchback104 from "../Images/Cateagory/HatchBack/Swift Hatchback107.jpeg";
import SwiftHatchback105 from "../Images/Cateagory/HatchBack/Swift Hatchback108.jpeg";

// Swift Desire Manual Sedan 201

import SwiftDesireSedan201 from "../Images/Cateagory/Sedan/Swift Desire Manual Sedan 201.png";
import SwiftDesireSedan202 from "../Images/Cateagory/Sedan/Swift Desire Manual Sedan 202.png";
import SwiftDesireSedan203 from "../Images/Cateagory/Sedan/Swift Desire Manual Sedan 203.png";
import SwiftDesireSedan204 from "../Images/Cateagory/Sedan/Swift Desire Manual Sedan 204.jpeg";
import SwiftDesireSedan205 from "../Images/Cateagory/Sedan/Swift Desire Manual Sedan 206.png";

// Vento Manual Sedan 101

import VentoManualSedan101 from "../Images/Cateagory/Sedan/Vento Manual Sedan 101.png";
import VentoManualSedan102 from "../Images/Cateagory/Sedan/Vento Manual Sedan 109.jpeg";
import VentoManualSedan103 from "../Images/Cateagory/Sedan/Vento Manual Sedan 103.jpeg";
import VentoManualSedan104 from "../Images/Cateagory/Sedan/Vento Manual Sedan 104.png";
import VentoManualSedan105 from "../Images/Cateagory/Sedan/Vento Manual Sedan 106.jpeg";

// manual ertiga muv

import ErtigaMuv01 from "../Images/Cateagory/Muv/manual ertiga  muv 101.png";
import ErtigaMuv02 from "../Images/Cateagory/Muv/manual ertiga  muv 102.png";
import ErtigaMuv03 from "../Images/Cateagory/Muv/manual ertiga  muv 103.png";
import ErtigaMuv04 from "../Images/Cateagory/Muv/manual ertiga  muv 104.png";
import ErtigaMuv05 from "../Images/Cateagory/Muv/manual ertiga  muv 105.png";

// brezza manual muv 205

import BrezzaMuv201 from "../Images/Cateagory/Muv/brezza manual muv 205.png";
import BrezzaMuv202 from "../Images/Cateagory/Muv/brezza manual muv 202.png";
import BrezzaMuv203 from "../Images/Cateagory/Muv/brezza manual muv 203.png";
import BrezzaMuv204 from "../Images/Cateagory/Muv/brezza manual muv 204.jpeg";
import BrezzaMuv205 from "../Images/Cateagory/Muv/brezza manual muv 206.jpeg";

// brezza manual muv 301

import BrezzaMuv301 from "../Images/Cateagory/Muv/brezza manual muv 301.png";
import BrezzaMuv302 from "../Images/Cateagory/Muv/brezza manual muv 302.png";
import BrezzaMuv303 from "../Images/Cateagory/Muv/brezza manual muv 303.jpeg";
import BrezzaMuv304 from "../Images/Cateagory/Muv/brezza manual muv 304.png";
import BrezzaMuv305 from "../Images/Cateagory/Muv/brezza manual muv 305.jpeg";


// vanue manual muv 401

import VanueMuv401 from "../Images/Cateagory/Muv/vanue manual muv 401.png";
import VanueMuv402 from "../Images/Cateagory/Muv/vanue manual muv 402.png";
import VanueMuv403 from "../Images/Cateagory/Muv/vanue manual muv 403.png";
import VanueMuv404 from "../Images/Cateagory/Muv/vanue manual muv 404.jpeg";
import VanueMuv405 from "../Images/Cateagory/Muv/vanue manual muv 405.jpeg";

// innova manual suv 101

import Innova101 from "../Images/Cateagory/Suv/innova manual suv 101.jpeg";
import Innova102 from "../Images/Cateagory/Suv/innova manual suv 102.png";
import Innova103 from "../Images/Cateagory/Suv/innova manual suv 107.jpeg";
import Innova104 from "../Images/Cateagory/Suv/innova manual suv 104.png";
import Innova105 from "../Images/Cateagory/Suv/innova manual suv 105.png";

// Mercedes E250d 101

import MercedesE250d101 from "../Images/Cateagory/Luxry/Mercedes E250d 101.jpeg";
import MercedesE250d102 from "../Images/Cateagory/Luxry/Mercedes E250d 102.png";
import MercedesE250d103 from "../Images/Cateagory/Luxry/Mercedes E250d 106.jpeg";
import MercedesE250d104 from "../Images/Cateagory/Luxry/Mercedes E250d 104.png";
import MercedesE250d105 from "../Images/Cateagory/Luxry/Mercedes E250d 105.png";

// BMW 520d 201

import BMW520d202 from "../Images/Cateagory/Luxry/BMW 520d 207.jpeg";
import BMW520d201 from "../Images/Cateagory/Luxry/BMW 520d 202.png";
import BMW520d203 from "../Images/Cateagory/Luxry/BMW 520d 203.jpeg";
import BMW520d204 from "../Images/Cateagory/Luxry/BMW 520d 204.jpeg";
import BMW520d205 from "../Images/Cateagory/Luxry/BMW 520d 205.jpeg";

// Mercedes C220d 301

import MercedesC220d301 from "../Images/Cateagory/Luxry/Mercedes C220d 301.png";
import MercedesC220d302 from "../Images/Cateagory/Luxry/Mercedes C220d 302.png";
import MercedesC220d303 from "../Images/Cateagory/Luxry/Mercedes C220d 303.png";
import MercedesC220d304 from "../Images/Cateagory/Luxry/Mercedes C220d 304.png";
import MercedesC220d305 from "../Images/Cateagory/Luxry/Mercedes C220d 307.png";

// BMW 320d 401

import BMW320d401 from "../Images/Cateagory/Luxry/BMW 320d 401.png";
import BMW320d402 from "../Images/Cateagory/Luxry/BMW 320d 406.jpeg";
import BMW320d403 from "../Images/Cateagory/Luxry/BMW 320d 403.jpeg";
import BMW320d404 from "../Images/Cateagory/Luxry/BMW 320d 404.jpeg";
import BMW320d405 from "../Images/Cateagory/Luxry/BMW 320d 405.jpeg";

// Toyota Fortuner

import ToyotaFortuner01 from "../Images/Cateagory/Suv/Toyota Fortuner 01.jpeg";
import ToyotaFortuner02 from "../Images/Cateagory/Suv/Toyota Fortuner 06.jpeg";
import ToyotaFortuner03 from "../Images/Cateagory/Suv/Toyota Fortuner 03.jpeg";
import ToyotaFortuner04 from "../Images/Cateagory/Suv/Toyota Fortuner 08.jpeg";
import ToyotaFortuner05 from "../Images/Cateagory/Suv/Toyota Fortuner 05.jpeg";

const CarsData = [
    {
        id: 1,
        category: "Hatchback",
        CarsImage01: SwiftHatchback101,
        CarsImage02: SwiftHatchback102,
        CarsImage03: SwiftHatchback103,
        CarsImage04: SwiftHatchback104,
        CarsImage05: SwiftHatchback105,
        CarsName: "Swift",
        FillStar1: <HiStar />,
        FillStar2: <HiStar />,
        FillStar3: <HiStar />,
        FillStar4: <HiStar />,
        FillStar5: <HiStar />,
        Reviews: "5 reviews",
        People: "4",
        Bags: "4",
        GType: "Auto",
    },
    {
        id: 2,
        category: "Hatchback",
        CarsImage01: BalenoHatchback201,
        CarsImage02: BalenoHatchback202,
        CarsImage03: BalenoHatchback203,
        CarsImage04: BalenoHatchback204,
        CarsImage05: BalenoHatchback205,
        CarsName: "Baleno",
        FillStar1: <HiStar />,
        FillStar2: <HiStar />,
        FillStar3: <HiStar />,
        FillStar4: <HiStar />,
        FillStar5: <HiOutlineStar />,
        Reviews: "4 reviews",
        People: "4",
        Bags: "4",
        GType: "Manual",
    },
    {
        id: 3,
        category: "Sedan",
        CarsImage01: SwiftDesireSedan201,
        CarsImage02: SwiftDesireSedan202,
        CarsImage03: SwiftDesireSedan203,
        CarsImage04: SwiftDesireSedan204,
        CarsImage05: SwiftDesireSedan205,
        CarsName: "Swift Desire",
        FillStar1: <HiStar />,
        FillStar2: <HiStar />,
        FillStar3: <HiStar />,
        FillStar4: <HiStar />,
        FillStar5: <HiOutlineStar />,
        Reviews: "4 reviews",
        People: "4",
        Bags: "4",
        GType: "Manual",
    },
    {
        id: 4,
        category: "Sedan",
        CarsImage01: VentoManualSedan101,
        CarsImage02: VentoManualSedan102,
        CarsImage03: VentoManualSedan103,
        CarsImage04: VentoManualSedan104,
        CarsImage05: VentoManualSedan105,
        CarsName: "Hyundai Creta",
        FillStar1: <HiStar />,
        FillStar2: <HiStar />,
        FillStar3: <HiStar />,
        FillStar4: <HiStar />,
        FillStar5: <HiStar />,
        Reviews: "5 reviews",
        People: "4",
        Bags: "4",
        GType: "Manual",
    },
    {
        id: 5,
        category: "Muv Cars",
        CarsImage01: ErtigaMuv01,
        CarsImage02: ErtigaMuv02,
        CarsImage03: ErtigaMuv03,
        CarsImage04: ErtigaMuv04,
        CarsImage05: ErtigaMuv05,
        CarsName: "Ertiga",
        FillStar1: <HiStar />,
        FillStar2: <HiStar />,
        FillStar3: <HiStar />,
        FillStar4: <HiStar />,
        FillStar5: <HiStar />,
        Reviews: "5 reviews",
        People: "4",
        Bags: "4",
        GType: "Manual",
    },
    {
        id: 6,
        category: "Muv Cars",
        CarsImage01: BrezzaMuv201,
        CarsImage02: BrezzaMuv202,
        CarsImage03: BrezzaMuv203,
        CarsImage04: BrezzaMuv204,
        CarsImage05: BrezzaMuv205,
        CarsName: "Brezza",
        FillStar1: <HiStar />,
        FillStar2: <HiStar />,
        FillStar3: <HiStar />,
        FillStar4: <HiStar />,
        FillStar5: <HiStar />,
        Reviews: "5 reviews",
        People: "4",
        Bags: "4",
        GType: "Manual",
    },
    {
        id: 7,
        category: "Muv Cars",
        CarsImage01: BrezzaMuv301,
        CarsImage02: BrezzaMuv302,
        CarsImage03: BrezzaMuv303,
        CarsImage04: BrezzaMuv304,
        CarsImage05: BrezzaMuv305,
        CarsName: "Brezza",
        FillStar1: <HiStar />,
        FillStar2: <HiStar />,
        FillStar3: <HiStar />,
        FillStar4: <HiStar />,
        FillStar5: <HiStar />,
        Reviews: "5 reviews",
        People: "4",
        Bags: "4",
        GType: "Manual",
    },
    {
        id: 8,
        category: "Muv Cars",
        CarsImage01: VanueMuv401,
        CarsImage02: VanueMuv402,
        CarsImage03: VanueMuv403,
        CarsImage04: VanueMuv404,
        CarsImage05: VanueMuv405,
        CarsName: "Hyundai Creta",
        FillStar1: <HiStar />,
        FillStar2: <HiStar />,
        FillStar3: <HiStar />,
        FillStar4: <HiStar />,
        FillStar5: <HiStar />,
        Reviews: "5 reviews",
        People: "4",
        Bags: "4",
        GType: "Manual",
    },
    {
        id: 8,
        category: "Suv Cars",
        CarsImage01: Innova101,
        CarsImage02: Innova102,
        CarsImage03: Innova103,
        CarsImage04: Innova104,
        CarsImage05: Innova105,
        CarsName: "Innova",
        FillStar1: <HiStar />,
        FillStar2: <HiStar />,
        FillStar3: <HiStar />,
        FillStar4: <HiStar />,
        FillStar5: <HiStar />,
        Reviews: "5 reviews",
        People: "4",
        Bags: "4",
        GType: "Manual",
    },
    {
        id: 9,
        category: "Luxry Car",
        CarsImage01: MercedesE250d101,
        CarsImage02: MercedesE250d102,
        CarsImage03: MercedesE250d103,
        CarsImage04: MercedesE250d104,
        CarsImage05: MercedesE250d105,
        CarsName: "Mercedes E250d",
        FillStar1: <HiStar />,
        FillStar2: <HiStar />,
        FillStar3: <HiStar />,
        FillStar4: <HiStar />,
        FillStar5: <HiStar />,
        Reviews: "5 reviews",
        People: "4",
        Bags: "4",
        GType: "Manual",
    },
    {
        id: 10,
        category: "Luxry Car",
        CarsImage01: BMW520d201,
        CarsImage02: BMW520d202,
        CarsImage03: BMW520d203,
        CarsImage04: BMW520d204,
        CarsImage05: BMW520d205,
        CarsName: "BMW 520d",
        FillStar1: <HiStar />,
        FillStar2: <HiStar />,
        FillStar3: <HiStar />,
        FillStar4: <HiStar />,
        FillStar5: <HiStar />,
        Reviews: "5 reviews",
        People: "4",
        Bags: "4",
        GType: "Manual",
    },
    {
        id: 11,
        category: "Luxry Car",
        CarsImage01: MercedesC220d301,
        CarsImage02: MercedesC220d302,
        CarsImage03: MercedesC220d303,
        CarsImage04: MercedesC220d304,
        CarsImage05: MercedesC220d305,
        CarsName: "Mercedes C220d",
        FillStar1: <HiStar />,
        FillStar2: <HiStar />,
        FillStar3: <HiStar />,
        FillStar4: <HiStar />,
        FillStar5: <HiStar />,
        Reviews: "5 reviews",
        People: "4",
        Bags: "4",
        GType: "Manual",
    },
    {
        id: 12,
        category: "Luxry Car",
        CarsImage01: BMW320d401,
        CarsImage02: BMW320d402,
        CarsImage03: BMW320d403,
        CarsImage04: BMW320d404,
        CarsImage05: BMW320d405,
        CarsName: "BMW 320d",
        FillStar1: <HiStar />,
        FillStar2: <HiStar />,
        FillStar3: <HiStar />,
        FillStar4: <HiStar />,
        FillStar5: <HiStar />,
        Reviews: "5 reviews",
        People: "4",
        Bags: "4",
        GType: "Manual",
    },
    {
        id: 13,
        category: "Suv Cars",
        CarsImage01: ToyotaFortuner01,
        CarsImage02: ToyotaFortuner02,
        CarsImage03: ToyotaFortuner03,
        CarsImage04: ToyotaFortuner04,
        CarsImage05: ToyotaFortuner05,
        CarsName: "Toyota Fortuner",
        FillStar1: <HiStar />,
        FillStar2: <HiStar />,
        FillStar3: <HiStar />,
        FillStar4: <HiStar />,
        FillStar5: <HiStar />,
        Reviews: "5 reviews",
        People: "4",
        Bags: "4",
        GType: "Manual",
    },

]

export default CarsData;